import { db } from '../../firebase';
import { collection, query, orderBy, getDoc, where, limit, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from 'moment';

const functions = getFunctions();

export const fetchNewTings = async () => {
    try {
        let lastSnapshot = window.localStorage.getItem('lastSnapshotTings');
        console.log('lastSnapshot', lastSnapshot);

        let storedPosts = new Array();

        let downloadedTings = window.localStorage.getItem('tingsDB');
        console.log('tingsDB', downloadedTings);
        if (downloadedTings !== null && downloadedTings !== undefined && downloadedTings !== "undefined") {
            storedPosts = JSON.parse(downloadedTings);
        }

        const fetchNewTingsFromFirestore = httpsCallable(functions, 'firestore-fetchBoardTings');
        const dataOfTings = await fetchNewTingsFromFirestore({ lastSnapshot, storedPostsLength: storedPosts.length });
        console.log('data', dataOfTings);
        let newPosts = dataOfTings.data.newPosts;
        const finishedDownloadingTings = dataOfTings.data.finishedDownloadingTings;
        const lastVisible = dataOfTings.data.lastVisible;
        console.log('newPosts.length', newPosts.length);

        const twentyFourHoursAgo = moment().subtract(24, 'hours').toDate();

        if (newPosts.length > 0) {
            finishedDownloadingTings && window.localStorage.setItem('lastSnapshotTings', moment(new Date()).subtract(1, 'minutes').toISOString());

            if (newPosts.length === 0) {
                storedPosts = newPosts;
            } else {
                // Update old post with new changes:
                newPosts.forEach((item) => {
                    const index = storedPosts.findIndex((post) => post.id === item.id);

                    if (index !== -1) {
                        if (item.deletedTime) {
                            storedPosts.splice(index, 1);
                        } else {
                            storedPosts[index] = item;
                        }
                    } else {
                        console.log('not in list');
                        !item.deletedTime && storedPosts.unshift(item); // Add to the top of the list
                    }
                });
            }
        }

        // Filter out posts older than 24 hours
        storedPosts = storedPosts.filter(post => {
            const postTime = moment(post.postTime._seconds * 1000).toDate();
            return postTime >= twentyFourHoursAgo;
        });

        console.log('storedPosts', storedPosts);

        window.localStorage.setItem('tingsDB', JSON.stringify(storedPosts));

        const loaded = false;

        return {
            storedPosts, lastVisible, loaded
        };
    } catch (e) {
        console.log(e);
    }
};